import { render, staticRenderFns } from "./DialogDelete.vue?vue&type=template&id=e2bd3470"
import script from "./DialogDelete.vue?vue&type=script&lang=js"
export * from "./DialogDelete.vue?vue&type=script&lang=js"
import style0 from "./DialogDelete.vue?vue&type=style&index=0&id=e2bd3470&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/fluent/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e2bd3470')) {
      api.createRecord('e2bd3470', component.options)
    } else {
      api.reload('e2bd3470', component.options)
    }
    module.hot.accept("./DialogDelete.vue?vue&type=template&id=e2bd3470", function () {
      api.rerender('e2bd3470', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "portals/teachers-portal/src/global/components/DialogDelete.vue"
export default component.exports