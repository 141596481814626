var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: _vm.maxWidth, persistent: "" },
      scopedSlots: _vm._u(
        [
          _vm.$scopedSlots.activator
            ? {
                key: "activator",
                fn: function (scope) {
                  return [_vm._t("activator", null, null, scope)]
                },
              }
            : null,
        ],
        null,
        true
      ),
      model: {
        value: _vm.dialogVisibility,
        callback: function ($$v) {
          _vm.dialogVisibility = $$v
        },
        expression: "dialogVisibility",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "mk-card-radius pa-4" },
        [
          _c(
            "v-card-title",
            { staticClass: "justify-center" },
            [
              _vm._t("title", function () {
                return [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-column align-center" },
                    [
                      _c("delete-icon"),
                      _c("div", { staticStyle: { height: "15px" } }),
                      !_vm.$language
                        ? _c("p", { staticClass: "alert-title text-center" }, [
                            _vm._v("Delete " + _vm._s(_vm.itemText)),
                          ])
                        : _c("p", { staticClass: "alert-title text-center" }, [
                            _vm._v(_vm._s(_vm.itemText) + " ডিলিট করুন"),
                          ]),
                    ],
                    1
                  ),
                ]
              }),
            ],
            2
          ),
          _c(
            "v-card-text",
            { staticClass: "alert-text" },
            [
              !_vm.$language
                ? _vm._t("default", function () {
                    return [
                      _vm._v(
                        "Are you sure you want to delete this " +
                          _vm._s(_vm.itemText) +
                          "?"
                      ),
                    ]
                  })
                : _vm._t("default", function () {
                    return [
                      _vm._v(
                        "আপনি কি " + _vm._s(_vm.itemText) + " ডিলিট করতে চান?"
                      ),
                    ]
                  }),
            ],
            2
          ),
          _c(
            "v-card-actions",
            { staticClass: "justify-center" },
            [
              !_vm.$language
                ? _c(
                    "v-btn",
                    {
                      attrs: { outlined: "", rounded: "", color: "primary" },
                      on: { click: _vm.cancel },
                    },
                    [_vm._v("Cancel")]
                  )
                : _c(
                    "v-btn",
                    {
                      attrs: { outlined: "", rounded: "", color: "primary" },
                      on: { click: _vm.cancel },
                    },
                    [_vm._v("বাতিল করুন")]
                  ),
              !_vm.$language
                ? _c(
                    "v-btn",
                    {
                      staticClass: "white--text",
                      attrs: {
                        rounded: "",
                        color: "red accent-3",
                        loading: _vm.deleting,
                      },
                      on: { click: _vm.submit },
                    },
                    [_vm._v("Delete")]
                  )
                : _c(
                    "v-btn",
                    {
                      staticClass: "white--text",
                      attrs: {
                        rounded: "",
                        color: "red accent-3",
                        loading: _vm.deleting,
                      },
                      on: { click: _vm.submit },
                    },
                    [_vm._v("ডিলিট করুন")]
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }